// imports
import Vue from 'vue'
import ColorRadioButton from './components/ColorRadioButton.vue'
import ProductColor from './components/ProductColor.vue'
import ProductImage from './components/ProductImage.vue'
import AddressesComponent from './components/AddressesComponent.vue'
import LinkUpper from './components/LinkUpper/LinkUpper.vue'
import LangLinkModifier from './components/LangLinkModifier.vue'
import StateLoader from './components/StateLoader.vue'
import RadioButton from './components/RadioButton.vue'
import RadioButtonExtended from './components/RadioButtonExtended.vue'
import ExpandableDescription from './components/ExpandableDescription.vue'
import CustomerBlock from './components/CustomerBlock.vue'
import 'swiper/swiper-bundle.css'

// component register
Vue.component('ColorRadioButton', ColorRadioButton)
Vue.component('ProductColor', ProductColor)
Vue.component('ProductImage', ProductImage)
Vue.component('AddressesComponent', AddressesComponent)
Vue.component('LinkUpper', LinkUpper)
Vue.component('LangLinkModifier', LangLinkModifier)
Vue.component('StateLoader', StateLoader)
Vue.component('RadioButton', RadioButton)
Vue.component('RadioButtonExtended', RadioButtonExtended)
Vue.component('ExpandableDescription', ExpandableDescription)
Vue.component('CustomerBlock', CustomerBlock)
